// Supports font weights 200 - 800
import "@fontsource-variable/manrope";

import { browserTracingIntegration, init as initSentry } from "@sentry/vue";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import dayjsIsBetween from "dayjs/plugin/isBetween";
import { createPinia } from "pinia";
import { createApp } from "vue";

import { appConfig } from "#config/app";
import App from "./App.vue";
import { componentsPlugin } from "./plugins/components";
import { vueQueryPlugin } from "./plugins/vue-query";
import { vuetifyPlugin } from "./plugins/vuetify";
import { router } from "./router";

import "#styles/app.scss";

dayjs.extend(dayjsDuration);
dayjs.extend(dayjsIsBetween);

const app = createApp(App);
const pinia = createPinia();

initSentry({
  app,
  environment: appConfig.environment,
  dsn: "https://f527b4dd894437a23d5edc98877f2e66@o4507690896850944.ingest.us.sentry.io/4507690899275776",
  integrations: [browserTracingIntegration({ router, routeLabel: "path" })],
  // Performance monitoring (capture 100% of transactions)
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // TODO: Update with API_URL (validate it will not cause CORS issues!)
  tracePropagationTargets: ["localhost", appConfig.links.api],
});

// NOTE: Because this uses an conditional/async import, MSW may not be started before other requests!
//         This causes a race condition that will skip MSW for initial requests. To mitigate this, a
//         global promise is added (dev only) that will only complete when MSW finished loading! This
//         is utilized in the 'AppLoader' component to only mount the rest of the app once loaded.
//       While not the official recommendation, it is a better DX (and still only affects devs)
// Source: https://mswjs.io/docs/recipes/deferred-mounting
// Source: https://github.com/mswjs/msw/issues/73#issuecomment-601533852
if (appConfig.development) {
  // DEBUG: Can enable MSW once required again
  // window.mswReady = import("#api/msw/browser").then(({ worker }) => worker.start());
}

app.use(router).use(componentsPlugin).use(vuetifyPlugin).use(vueQueryPlugin).use(pinia);

app.mount("#app");
