<script lang="ts" setup>
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { computed, ref, toRefs } from "vue";
import { ForwardSlots } from "vue-forward-slots";
import { VTextField } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

interface TextFieldProps extends FieldInputProps {
  type?: string;
}

const props = withDefaults(defineProps<TextFieldProps>(), {
  disabled: false,
  hint: "",
  label: undefined,
  type: "text",
});

// NOTE: Must redefine expected Vuetify "child" slots for types to work with 'vue-forward-slots'!
defineSlots<VTextField["$slots"]>();

defineOptions({
  // Must prevent attributes from falling through to 'ForwardSlots' (instead bind to Vuetify component)!
  inheritAttrs: false,
});

const { disabled, error, field } = useFormInput(toRefs(props));
const { value: formValue, handleBlur, handleChange } = field;

const passwordHidden = ref(true);

const inputType = computed(() => {
  if (props.type !== "password") return props.type;
  return passwordHidden.value ? "password" : "text";
});
const inputIcon = computed(() =>
  props.type === "password" ? (passwordHidden.value ? mdiEyeOff : mdiEye) : undefined,
);

const toggleHidden = () => {
  passwordHidden.value = !passwordHidden.value;
};
</script>

<template>
  <ForwardSlots :slots="$slots">
    <VTextField
      v-bind="$attrs"
      :append-inner-icon="inputIcon"
      :disabled="disabled || props.disabled"
      :error="!!error"
      :hint="error ?? hint"
      :label="label"
      :model-value="formValue"
      :type="inputType"
      @blur="handleBlur"
      @click:append-inner="toggleHidden"
      @update:model-value="handleChange"
    />
  </ForwardSlots>
</template>
