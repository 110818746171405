<script lang="ts" setup>
import { toRefs } from "vue";
import { ForwardSlots } from "vue-forward-slots";
import { VSelect } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

interface SelectFieldProps extends FieldInputProps {}

const props = withDefaults(defineProps<SelectFieldProps>(), {
  disabled: false,
  hint: "",
  label: undefined,
  type: "text",
});

// NOTE: Must redefine expected Vuetify "child" slots for types to work with 'vue-forward-slots'!
defineSlots<VSelect["$slots"]>();

defineOptions({
  // Must prevent attributes from falling through to 'ForwardSlots' (instead bind to Vuetify component)!
  inheritAttrs: false,
});

const { disabled, error, field } = useFormInput(toRefs(props));
const { value: formValue, handleBlur, handleChange } = field;
</script>

<template>
  <ForwardSlots :slots="$slots">
    <VSelect
      v-bind="$attrs"
      :disabled="disabled || props.disabled"
      :error="!!error"
      :hint="error ?? hint"
      :label="label"
      :model-value="formValue"
      @blur="handleBlur"
      @update:model-value="handleChange"
    />
  </ForwardSlots>
</template>
