<script lang="ts" setup>
import {
  mdiBug,
  mdiMonitor as mdiComputer,
  mdiClipboard as mdiCopy,
  mdiCellphone as mdiMobile,
} from "@mdi/js";
import { useClipboard, useMagicKeys, whenever } from "@vueuse/core";
import { useDisplay } from "vuetify";

import { useAppTheme } from "#composables/use-app-theme";
import { useCountdown } from "#composables/use-countdown";
import { useDialog } from "#composables/use-dialog";
import { useStorageState } from "#composables/use-storage-state";
import { appConfig } from "#config/app";

const { mobile, name: breakpointName } = useDisplay();
const shortKeys = useMagicKeys();

const showScreenSize = useStorageState("screen-size", {
  defaultValue: false,
  // NOTE: Boolean values cannot use `isValid` due to boolean serializer (defaults to `false`)
});

const handleScreenSizeToggle = () => {
  showScreenSize.value = !showScreenSize.value;
};

const showScreenSizeKeys = shortKeys["Ctrl+Shift+Alt+m"];
whenever(showScreenSizeKeys, handleScreenSizeToggle);

const debugDialog = useDialog();

const debugKeys = shortKeys["Ctrl+Shift+Alt+d"];
whenever(debugKeys, () => {
  debugDialog.toggle(!debugDialog.open.value);
});

const appTheme = useAppTheme();

const themeKeys = shortKeys["Ctrl+Shift+Alt+t"];
whenever(themeKeys, appTheme.toggleTheme);

const { copy, isSupported: copySupported } = useClipboard();

const handleVersionCopy = (value: string) => {
  copy(value);
};

const sentryTimer = useCountdown();

const handleTriggerSentry = () => {
  sentryTimer.start(30);
  // @ts-expect-error
  callNotImplemented();
};
</script>

<template>
  <LayoutStack v-if="showScreenSize" class="debug-display ma-2 bg-white">
    <VChip label :prepend-icon="mobile ? mdiMobile : mdiComputer" :text="breakpointName" />
  </LayoutStack>

  <VDialog v-model="debugDialog.open.value" max-width="500">
    <VCard>
      <VCardTitle>Debug</VCardTitle>
      <VCardText class="pt-0">
        <div class="text-subtitle-1 mb-2">App Configuration</div>
        <LayoutStack class="mb-3">
          <LayoutStack align-items="center" direction="row">
            Git Commit:
            <VKbd>{{ appConfig.commitSha ?? "N/A" }}</VKbd>
            <VBtn
              v-if="copySupported"
              density="comfortable"
              :icon="mdiCopy"
              size="small"
              variant="text"
              @click="handleVersionCopy(appConfig.commitSha ?? 'N/A')"
            />
          </LayoutStack>
          <LayoutStack align-items="center" direction="row">
            Environment:
            <VKbd>{{ appConfig.environment }}</VKbd>
            <VBadge
              v-tooltip:bottom="appConfig.development ? 'Development' : 'Production'"
              :color="appConfig.development ? 'warning' : 'primary'"
              dot
              inline
            />
          </LayoutStack>
        </LayoutStack>

        <div class="text-subtitle-1 mb-2">Settings</div>
        <LayoutStack>
          <VSwitch
            hide-details
            label="Display screen size indicator"
            :model-value="showScreenSize"
            @update:model-value="(value) => (showScreenSize = value ?? false)"
          />
        </LayoutStack>

        <div class="text-subtitle-1 mb-2">Debug</div>
        <LayoutStack>
          <VBtn
            color="error"
            :disabled="sentryTimer.active.value"
            :prepend-icon="mdiBug"
            variant="text"
            @click="handleTriggerSentry"
          >
            Trigger Sentry
          </VBtn>
        </LayoutStack>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss">
.debug-display {
  z-index: 1005;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: spacing(2);
}
</style>
