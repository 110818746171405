<script lang="ts" setup>
import { computed, ref, toRef, toRefs } from "vue";
import { ForwardSlots } from "vue-forward-slots";
import { VFileInput } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

interface FileFieldProps extends FieldInputProps {
  accept?: string;
}

const props = withDefaults(defineProps<FileFieldProps>(), {
  accept: "image/png, image/jpeg",
  disabled: false,
  hint: "",
  label: undefined,
});

// NOTE: Must redefine expected Vuetify "child" slots for types to work with 'vue-forward-slots'!
defineSlots<VFileInput["$slots"]>();

defineOptions({
  // Must prevent attributes from falling through to 'ForwardSlots' (instead bind to Vuetify component)!
  inheritAttrs: false,
});

const inputRef = ref<HTMLInputElement>();

/** Trigger HTML input file picker */
const triggerInput = () => {
  inputRef.value?.click();
};

defineExpose({
  /** Trigger HTML input file picker */
  triggerInput,
});

const acceptRef = toRef(props, "accept");

const { disabled, error, field } = useFormInput(toRefs(props));
const { handleBlur, handleChange } = field;
const formValue = computed(() => field.value.value as unknown as File[]);

const handleFileClear = () => {
  handleChange([]);
};

const handleFileChange = (files: File | File[]) => {
  const fileList = Array.isArray(files) ? files : [files];
  // Prevent clearing files when "cancel" is pressed (can clear with dedicated "clear" button)
  if (!fileList) return;

  handleChange(fileList);
};
</script>

<template>
  <ForwardSlots :slots="$slots">
    <VFileInput
      v-bind="$attrs"
      ref="inputRef"
      :accept="acceptRef"
      class="file-input"
      :disabled="disabled"
      :error="!!error"
      :hint="error ?? hint"
      :label="label"
      :model-value="formValue"
      @blur="handleBlur"
      @click:clear="handleFileClear"
      @update:model-value="handleFileChange"
    />
  </ForwardSlots>
</template>

<style lang="scss" scoped>
.file-input :deep(.v-field__input) {
  // Prevent long filenames from expanding
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
