import dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { type Season } from "./season.types";

export const useSeasonStore = defineStore("season", () => {
  const currentSeason = ref<Season | undefined>();

  const beforeSubmissionsEnd = computed<boolean>(() => {
    if (!currentSeason.value) return false;

    const submissionsEnd = dayjs(currentSeason.value.submissionsEnd).endOf("day");
    return dayjs().isBefore(submissionsEnd);
  });

  const afterSubmissionsStart = computed<boolean>(() => {
    if (!currentSeason.value) return false;

    const submissionsStart = dayjs(currentSeason.value.submissionsStart).startOf("day");
    return dayjs().isAfter(submissionsStart);
  });

  const acceptingSubmissions = computed<boolean>(() => {
    if (!currentSeason.value) return false;

    return beforeSubmissionsEnd.value && afterSubmissionsStart.value;
  });

  return {
    /** Whether submissions are currently being accepted (ie. is today within start/end of current season) */
    acceptingSubmissions,
    /**
     * Whether today is before submissions end.
     *
     * NOTE: Does not calculate whether submissions have started!
     */
    beforeSubmissionsEnd,
    /**
     * Whether today is after submissions start.
     *
     * NOTE: Does not calculate whether submissions have ended!
     */
    afterSubmissionsStart,
    currentSeason,
  };
});
