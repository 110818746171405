import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import type { Ref } from "vue";

import { pb } from "#api/pocketbase";
import type {
  CollaboratorFeedbackCurrentStats,
  CollaboratorReviewsCurrentStats,
  CollaboratorStats,
  ComposerStats,
  SubmissionsCurrentStats,
} from "./stats.types";

// NOTE: Query keys used for invalidation should require all arguments, as 'undefined' in query key
//         prevents invalidating any queries with similar key but that do provide args for that argument!
export const statKeys = {
  platformAll: ["stats", "platform"],
  platformCollaborators: () => [...statKeys.platformAll, "collaborators"],
  platformComposers: () => [...statKeys.platformAll, "composers"],
  platformFeedbackCurrent: () => [...statKeys.platformAll, "feedback", "current"],
  platformReviewsCurrent: () => [...statKeys.platformAll, "reviews", "current"],
  platformSubmissionsCurrent: () => [...statKeys.platformAll, "submissions", "current"],
} as const;

export const usePlatformStatsCollaboratorsQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformCollaborators(),
    queryFn: async () => {
      return pb.send<CollaboratorStats>("/api/stats/platform/collaborator", {});
    },
  });
};

export const usePlatformStatsComposersQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformComposers(),
    queryFn: async () => {
      return pb.send<ComposerStats>("/api/stats/platform/composer", {});
    },
  });
};

export const usePlatformStatsFeedbackCurrentQuery = (args?: { enabled?: Ref<boolean> }) => {
  return useQuery({
    enabled: args?.enabled,
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformFeedbackCurrent(),
    queryFn: async () => {
      return pb.send<CollaboratorFeedbackCurrentStats[]>(
        "/api/stats/platform/feedback/current",
        {},
      );
    },
  });
};

export const usePlatformStatsReviewsCurrentQuery = (args?: { enabled?: Ref<boolean> }) => {
  return useQuery({
    enabled: args?.enabled,
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformReviewsCurrent(),
    queryFn: async () => {
      return pb.send<CollaboratorReviewsCurrentStats[]>("/api/stats/platform/review/current", {});
    },
  });
};

export const usePlatformStatsSubmissionsCurrentQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformSubmissionsCurrent(),
    queryFn: async () => {
      return pb.send<SubmissionsCurrentStats>("/api/stats/platform/submission/current", {});
    },
  });
};
