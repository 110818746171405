<script lang="ts" setup>
import { toRefs } from "vue";
import { ForwardSlots } from "vue-forward-slots";
import { VTextarea } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

type TextareaFieldProps = FieldInputProps;

const props = withDefaults(defineProps<TextareaFieldProps>(), {
  disabled: false,
  hint: "",
  label: undefined,
});

// NOTE: Must redefine expected Vuetify "child" slots for types to work with 'vue-forward-slots'!
defineSlots<VTextarea["$slots"]>();

defineOptions({
  // Must prevent attributes from falling through to 'ForwardSlots' (instead bind to Vuetify component)!
  inheritAttrs: false,
});

const { disabled, error, field } = useFormInput(toRefs(props));
const { value: formValue, handleBlur, handleChange } = field;
</script>

<template>
  <ForwardSlots :slots="$slots">
    <VTextarea
      v-bind="$attrs"
      :disabled="disabled"
      :error="!!error"
      :hint="error ?? hint"
      :label="label"
      :model-value="formValue"
      @blur="handleBlur"
      @update:model-value="handleChange"
    />
  </ForwardSlots>
</template>
