<script lang="ts" setup>
import {
  mdiAccountMultiple as mdiComposers,
  mdiViewDashboard as mdiDashboard,
  mdiCalendar as mdiSeasons,
  mdiCog as mdiSettings,
  mdiFolderMusic as mdiSubmissions,
  mdiShieldLock as mdiTeam,
} from "@mdi/js";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify";

import { useAuthAccount } from "#composables/use-auth-account";
import {
  usePlatformStatsComposersQuery,
  usePlatformStatsSubmissionsCurrentQuery,
} from "#modules/stats/stats.queries";

defineProps<{
  /** Whether to show drawer (affects mobile only) */
  showOnMobile: boolean;
}>();

const emit = defineEmits<{
  (e: "toggle-drawer"): void;
}>();

interface DrawerNavItem {
  badge?: {
    color?: string;
    label?: string;
    value: string | number | undefined;
  };
  children?: DrawerNavItem[];
  disabled?: boolean;
  exact?: boolean;
  icon: string;
  to: string;
  title: string;
}

const composerStatsQuery = usePlatformStatsComposersQuery();
const pendingComposers = computed(() => composerStatsQuery.data.value?.pending);

const submissionsStatsQuery = usePlatformStatsSubmissionsCurrentQuery();
const submissionsForReview = computed(() => submissionsStatsQuery.data.value?.pending ?? 0);

// TODO: Investigate nesting nav items (ie. pending composers, etc), but still must support
//         navigating to "root" item (default nested list treats "parent" as non-interactable).
const drawerCollaboratorNavItems = computed<DrawerNavItem[]>(() => [
  { exact: true, icon: mdiDashboard, to: "/", title: "Dashboard" },
  {
    badge: submissionsForReview.value
      ? { color: "warning", label: "Pending", value: `+${submissionsForReview.value}` }
      : undefined,
    icon: mdiSubmissions,
    to: "/submissions",
    title: "Submissions",
  },
  {
    badge: pendingComposers.value
      ? { color: "warning", label: "Pending", value: `+${pendingComposers.value}` }
      : undefined,
    icon: mdiComposers,
    to: "/composers",
    title: "Composers",
  },
  { icon: mdiTeam, to: "/team", title: "Team" },
  { icon: mdiSeasons, to: "/seasons", title: "Seasons" },
  { disabled: true, icon: mdiSettings, to: "/settings", title: "Settings" },
]);

const { mobile } = useDisplay();
const account = useAuthAccount();
const route = useRoute();

const hideDrawer = computed(() => !account.isCollaborator || route.path.includes("logout"));
</script>

<template>
  <VNavigationDrawer
    v-if="!hideDrawer"
    :model-value="mobile ? showOnMobile : true"
    :permanent="!mobile"
    @update:model-value="emit('toggle-drawer')"
  >
    <VList color="primary" density="compact" nav>
      <VListItem
        v-for="navItem in drawerCollaboratorNavItems"
        :key="navItem.title"
        :disabled="navItem.disabled"
        :exact="navItem.exact"
        :title="navItem.title"
        :to="`/collaborator${navItem.to}`"
      >
        <template #append>
          <VBadge
            v-if="navItem.badge"
            v-tooltip:bottom="navItem.badge.label"
            :color="navItem.badge.color"
            :content="navItem.badge.value"
            inline
          />
        </template>
        <template #prepend>
          <VIcon :icon="navItem.icon" />
        </template>
      </VListItem>
    </VList>
  </VNavigationDrawer>
</template>
